import clusterData from "../../services"
import router from '@/router'
import helper from "@/helper"

export default {
    data() {
        return {
            clusterAdmin: {
                first_name: null,
                last_name: null,
                email: null,
                country_id: null,
                phone: null,
                otp: null,
            },
            selectData: {},
            formValidations: {
                first_name: v => !!v || "First Name is required",
                last_name: v => !!v || "Last Name is required",
                email: v => v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required",
                country: v => !!v || "Country is required",
                phone: v => v ? (/^\d+$/.test(v) || 'Phone must be valid') : "Phone is required",
                otp: v => v ? (/^(?=.{6,})/.test(v) || 'OTP must be valid') : "OTP is required",
            }
        }
    },
    mounted() {
        this.showLoader(true);
        clusterData.checkRegistrationLink(this.$route.query).then(responses => {
            Object.assign(this.clusterAdmin, responses.data.data);
            window.init_template();
            this.hideLoader();
        }).catch(() => {
            this.hideLoader();
            router.push({
                name: 'home'
            });
        });
    },
    methods: {
        submitForm() {
            this.showLoader(true);
            this.$popper.wait("Initiating registration...");
            clusterData.register(this.clusterAdmin).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
                if (response.data.token) {
                    this.$store.bus.emit("authToken", {
                        'access_token': response.data.token,
                        'expires': null
                    });
                    this.$store.bus.emit('user', response.data.data);
                    helper.rememberAuthorization();
                    this.$router.push({
                        name: 'dashboard-home'
                    });
                }
            });
        }
    }
}