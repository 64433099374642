import clusterData from "../../services";

export default {
    data() {
        return {
            clusterAdmin: { },
        }
    },
    mounted() {
        clusterData.getClusterAdminData(this.$route.params.id).then(response =>{
            this.clusterAdmin = response.data.data;
        });
    }
}