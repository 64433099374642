<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Cluster Admins</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-3">
        <i class="fa fa-search"></i>
    </a>
    <router-link :to="{name: 'cluster-admin-create'}" class="header-icon header-icon-2"
        v-if="hasPermission('cluster_admin_create')">
        <i class="fa fa-user-plus"></i>
    </router-link>
    <div class="header-icon header-icon-4 select-filter" style="width: 50px !important;" v-if="hasSuperAdmin">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder=""
            :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div v-for="(record, index) in clusterAdmins" v-if="clusterAdmins && clusterAdmins.length > 0" class="mt-2">
        <div class="card card-style mb-0">
            <div class="content mb-2 mt-2" id="tab-group-1">
                <router-link :to="{'name': 'cluster-admin-detail', params: {'id' : record.id}}">
                    <div class="d-flex">
                        <div class="align-self-center">
                            <h2 class="primary-light-text font-600 font-15 line-height-s mt-1 mb-1 letterSpacing">
                                {{record.business_name ? record.business_name + " - ": record.business_name }}{{
                                record.full_name }}
                            </h2>
                        </div>
                        <div class="ms-auto ps-3 align-items-end">
                            <p class="font-10 mb-1 text-end letterSpacing">{{ localDate(record.created_at) }}</p>
                            <h2 class="font-12 mb-0 text-end letterSpacing text-secondary">{{ record.country.emoji }}
                                &nbsp;{{ record.country.name }}
                            </h2>
                        </div>
                        <div class="d-flex ps-2 align-items-center">
                            <i class="fa fa-chevron-right font-10 text-secondary"></i>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
    <div class="mt-5 text-center" v-if="!(clusterAdmins.length>0)">
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No Cluster Admin Found
        </p>
    </div>
</div>
<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search " v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>