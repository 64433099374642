<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Edit Cluster Admin</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style mt-3 mx-4">
        <div class="content">
            <div class="col-12 text-center">
                <p class="color-highlight mb-0"></p>
            </div>

            <Form novalidate @submit="submitForm" :validation-schema="formValidations" v-slot="{ errors }" autocomplete="off">
                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.first_name}">
                    <i class="fa fa-user"></i>
                    <Field as="input" type="text" name="first_name" v-model="clusterAdmin.first_name" class="form-control" id="first_name" placeholder="First Name"></Field>
                    <label for="first_name" class="color-primary-dark font-13 font-600">First Name</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.first_name}}</template>
                </form-control>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.last_name}">
                    <i class="fa fa-user"></i>
                    <Field as="input" type="text" name="last_name" v-model="clusterAdmin.last_name" class="form-control" id="last_name" placeholder="Last Name"></Field>
                    <label for="last_name" class="color-primary-dark font-13 font-600">Last Name</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.last_name}}</template>
                </form-control>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.email}">
                    <i class="fa fa-at"></i>
                    <Field as="input" type="email" name="email" v-model="clusterAdmin.email" class="form-control" id="email" placeholder="Email"></Field>
                    <label for="email" class="color-primary-dark font-13 font-600">Email</label>
                    <em>(required)</em>
                    <template v-slot:errors>{{errors.email}}</template>
                </form-control>

                <div class="input-group raw">
                    <div class="col-4">
                        <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.country_id}">
                            <app-select name="country_id" placeholder="Country" :disabled="true" :options="$store.state.selectData.phoneCodes" value-prop="id" v-model="clusterAdmin.country_id">
                                <template v-slot:default="slotProps">
                                    {{ slotProps.item.emoji }} &nbsp;(+{{ slotProps.item.phone_code }})
                                </template>
                                <template v-slot:display="slotProps">
                                    <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                                    {{ slotProps.item.name }} <span class="">(+{{ slotProps.item.phone_code }})</span>
                                </template>
                            </app-select>
                            <template v-slot:errors>{{errors.country_id}}</template>
                        </form-control>
                    </div>
                    <div class="col ms-2">
                        <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.phone}">
                            <i class="fa fa-mobile"></i>
                            <Field as="input" type="number" name="phone" v-model="clusterAdmin.phone" class="form-control validate-number" id="phone" placeholder="Phone No" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="15"></Field>
                            <label for="phone" class="color-primary-dark font-13 font-600">Phone No</label>
                            <em>(required)</em>
                            <template v-slot:errors>{{errors.phone}}</template>
                        </form-control>
                    </div>
                </div>

                <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.state}">
                    <app-select name="state" placeholder="States" multiple="true" :options="selectData.states" value-prop="id" v-model="clusterAdmin.state">
                        <template v-slot:default="slotProps">
                            {{ slotProps.item.text }}
                        </template>
                    </app-select>
                    <template v-slot:error>{{errors.state}}</template>
                </form-control>

                <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.city}">
                    <app-select name="city" placeholder="City" multiple="true" :options="selectData.cities" value-prop="id" v-model="clusterAdmin.city">
                        <template v-slot:default="slotProps">
                            {{ slotProps.item.text }}
                        </template>
                    </app-select>
                    <template v-slot:error>{{errors.city}}</template>
                </form-control>

                <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.zone}">
                    <app-select name="zone" placeholder="Zones" multiple="true" :options="selectData.zones" value-prop="id" v-model="clusterAdmin.zone">
                        <template v-slot:default="slotProps">
                            {{ slotProps.item.text }}
                        </template>
                    </app-select>
                    <template v-slot:error>{{errors.zone}}</template>
                </form-control>

                <div class="d-flex justify-content-center mt-4">
                    <button class="btn btn-ripple btn btn-ripple-l font-600 font-13 primary-light-bg letterSpacing rounded-s">
                        Update
                    </button>
                </div>
            </Form>
        </div>
    </div>
</div>