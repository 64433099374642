import clusterData from "../../services";
import router from "@/router"

export default {
    data() {
        return {
            clusterAdmin: {},
            selectData: {},
            dataSource: {},
            formValidations: {
                first_name: v => !!v || "First Name is required",
                last_name: v => !!v || "Last Name is required",
                email: v => v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required",
                country_id: v => !!v || "Country is required",
                phone: v => v ? (/^\d+$/.test(v) || 'Phone must be valid') : "Phone is required",
            },
        }
    },
    mounted() {
        var select = {
            'type': ['states', 'cities', 'zones'],
            'country_id': this.clusterAdmin.country_id
        };
        this.showLoader(true);
        Promise.all([
            clusterData.getSelectData(select),
            clusterData.getClusterAdminData(this.$route.params.id)
        ]).then(responses => {
            Object.assign(this.clusterAdmin, responses[1].data.data);
            this.clusterAdmin.country_id = responses[1].data.data.country.id;
            this.clusterAdmin.partners = responses[1].data.data.partners ?
                responses[1].data.data.partners.map(function (object) {
                    return object['id'];
                }) : []


            this.selectData = responses[0].data;
            this.filterData();
            window.init_template();
            this.hideLoader();
        }).catch(() => {
            this.hideLoader();
            // router.push({
            //     name: 'home'
            // });
        });
    },
    methods: {
        submitForm() {
            this.showLoader(true);
            this.$popper.wait("Updating...");
            clusterData.updateClusterAdmin(this.clusterAdmin.id, this.clusterAdmin).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
                router.push({
                    'name': 'cluster-admin-detail',
                    'params': {
                        'id': this.clusterAdmin.id
                    }
                });
            });
        },
    }
}